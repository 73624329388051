import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="section-title text-center">
        <h2>Votre satisfaction, notre priorité !</h2>
      </div>
      <div className="section-title-description text-center">
        <p>
          Parce que nos clients sont nos meilleurs ambassadeurs, découvrez
          pourquoi tant de gens ont choisi MGB Solution pour gérer et faire
          prospérer leur épargne en toute tranquillité.
        </p>
      </div>
      <div className="testimonials-container">
        {props.data
          ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="testimonial">
                <div className="testimonial-image">
                  <img
                    src={d.img}
                    className="profile-img"
                    alt={`${d.name}'s testimonial`}
                  />
                </div>
                <div className="testimonial-content">
                  <p>{d.text}</p>
                  <div className="d-flex justify-content-between ">
                    <div className="testimonial-meta">
                      {" "}
                      <span>- {d.name}</span>
                      <img
                        src="/img/MGBsolution_étoile.png"
                        width={160}
                        alt=""
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>
            ))
          : "loading"}
      </div>
    </div>
  );
};
