import React from "react";
import { Navigation } from "./navigation";
import { Footer } from "./footer";

const MentionsLegales = () => {
  return (
    <div>
      <div class="mention-legales-header">
        <Navigation />
      </div>
      <div class="container">
        <br></br>
        <h2 class="section-title">Mentions Légales :</h2>
        <p>
          L’accès au site, sa consultation et son utilisation sont subordonnés à
          l’acceptation sans réserve des Conditions Générales d’Utilisation.
        </p>
        <br></br>
        <p>
          Le site Internet <strong>mgb-solution.fr</strong> est la propriété de
          :
        </p>
        <div class="company-info">
          <p>
            <strong>MGB Solution</strong>
            <br></br>Société à responsabilité limitée au capital social de 1 000
            € Immatriculation au RCS de Nantes : 905 243 234<br></br>Code NAF :
            6202A Numéro individuel d’identification à la TVA : FR53905243234
            <br></br>
          </p>
        </div>
        <p>
          <strong>Notre rémunération :</strong>{" "}
          <a href="http://localhost:3000/#services">Nos tarifs</a>
        </p>
        <div class="contact-info">
          <p>
            <strong>Siège social :</strong> 8, boulevard du tertre, 44800
            Saint-Herblain, France<br></br>
            Téléphone : +33 6 80 41 92 84<br></br>
            Email :{" "}
            <a href="mailto:quentin.maillard@mgb-solution.com">
              quentin.maillard@mgb-solution.com
            </a>
            <br></br>
            <br></br>
            <strong>Directeur de la publication :</strong> Monsieur Karl-Edouard
            Gläser
          </p>
          <br></br>
          <br></br>
          <br></br>
        </div>
        <div class="hosting-info">
          <h2 class="section-title">Hébergement :</h2>
          <p>
            <strong>OVHcloud</strong>
            <br></br>
            Société par actions simplifiée à associé unique au capital social de
            50 000 000,00 €<br></br>
            Immatriculation au RCS de Lille : 424 761 419<br></br>
            Siège social : 2 rue Kellermann - 59100 Roubaix - France<br></br>
            <a href="https://www.ovhcloud.com/fr/" target="_blank">
              www.ovhcloud.com
            </a>
          </p>
          <br></br>
          <br></br>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MentionsLegales;
