import React from "react";

export const Footer = (props) => {
  return (
    <div>
      <div id="footer">
        <div class="text-center">
          <p>
            &copy; 2024 MGB Solution -{" "}
            <a href="/mentions-legales" class="legal-link">
              Mentions légales
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
