import React, { useState, useEffect } from "react";

export const Navigation = () => {
  const [atBottom, setAtBottom] = useState(false);

  const handleScroll = () => {
    const isAtBottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 5; 
    setAtBottom(isAtBottom);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      id="menu"
      className={
        atBottom
          ? "navbar navbar-default navbar-footer navbar-fixed-top"
          : "navbar navbar-default navbar-fixed-top"
      }
    >
      <div>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            className="navbar-brand page-scroll"
            href="/"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={
                atBottom
                  ? "img/MGBsolution_logo_blanc.png"
                  : "img/MGBsolution_logo.png"
              }
              style={{ width: "245px", height: "70px", marginRight: "10px" }}
              alt=""
            />
            {/* <span>MGB Solution</span> */}
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li> */}
            <li>
              <a
                href="/#testimonials"
                className={atBottom ? "page-scroll footer" : "page-scroll"}
              >
                Avis
              </a>
            </li>
            <li>
              <a
                href="/#services"
                className={atBottom ? "page-scroll footer" : "page-scroll"}
              >
                Tarifs
              </a>
            </li>
            {/* <li>
              <a
                href="#features"
                className={atBottom ? "page-scroll footer" : "page-scroll"}
              >
                Roadmap
              </a>
            </li> */}
            <li>
              <a
                href="/#team"
                className={atBottom ? "page-scroll footer" : "page-scroll"}
              >
                Notre équipe
              </a>
            </li>
            <li>
              <a
                href="/#gallery"
                className={atBottom ? "page-scroll footer" : "page-scroll"}
              >
                FAQ
              </a>
            </li>
            {/* <li>
              <a href="#contact" className="page-scroll">
                Nous contacter
              </a>
            </li> */}
            {/* <li> */}
            <a href="/#contact" className="page-scroll">
              <button
                href="/#contact"
                className={atBottom ? "btn btn-nav-light btn-lg" : "btn btn-nav btn-lg"}
              >
                Nous contacter
              </button>
            </a>
            
              {/* <a href="/exe/MgbSolution-win32-x64.zip" download> */}
                <button
                  className={atBottom ? "btn btn-nav-light btn-lg" : "btn btn-nav btn-lg"} style={{ marginLeft: "-10px" }}
                >
                  Télécharger l'application
                </button>
              {/* </a> */}
            
          </ul>
        </div>
      </div>
    </nav>
  );
};
