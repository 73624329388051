import React from "react";

export const Gallery = (props) => {
  return (
    <div id="gallery">
    <div className="row">
      <div className="about-text section-title">
        <h2 className="text-center">FAQ</h2>
        <div>
          <h3 className="left">
            Quels sont les risques associés au trading et comment les minimisez-vous ?
          </h3>
          <div className="bar"></div>
        </div>
        <p className="left">
          Le trading comporte des risques, notamment la possibilité de perdre tout ou une partie de votre capital investi en raison de fluctuations imprévues du marché. Notre entreprise reconnaît ces risques et met en place des mesures pour les minimiser. Nous utilisons des algorithmes avancés et une analyse approfondie pour prendre des décisions d'investissement éclairées et réduire les risques liés à l'émotion et à la subjectivité humaine. De plus, nous proposons une gestion rigoureuse du risque pour protéger les investissements de nos clients et limiter les pertes potentielles.
        </p>
        <div>
          <h3 className="right">
            Quelle est la différence entre le trading manuel et le trading automatisé ?
          </h3>
          <div className="bar2"></div>
        </div>
        <p className="right">
          Le trading manuel implique que les traders prennent eux-mêmes les décisions d'achat et de vente en fonction de leur propre analyse et de leur intuition. En revanche, le trading automatisé utilise des algorithmes informatiques pour exécuter automatiquement des transactions en fonction de règles prédéfinies. Notre entreprise se spécialise dans le trading automatisé, ce qui permet une exécution rapide et précise des transactions sans intervention humaine.
        </p>
        <div>
          <h3 className="left">
            Quels sont les avantages du trading automatisé avec votre entreprise ?
          </h3>
          <div className="bar3"></div>
        </div>
        <p className="left">
          Le trading automatisé présente plusieurs avantages, notamment une exécution rapide des transactions, une réduction des erreurs émotionnelles, une capacité à surveiller les marchés 24h/24 et une adaptation rapide aux conditions changeantes du marché. Notre entreprise utilise des algorithmes sophistiqués et une analyse approfondie pour maximiser les rendements et minimiser les risques pour nos clients.
        </p>
        <div>
          <h3 className="right">
            Comment puis-je commencer à utiliser vos services de trading automatisé ?
          </h3>
          <div className="bar4"></div>
        </div>
        <p className="right">
          Pour commencer à utiliser nos services de trading automatisé, il vous suffit de vous inscrire sur notre plateforme en ligne et de créer un compte. Une fois votre compte activé, vous pourrez configurer vos préférences de trading et commencer à bénéficier de notre expertise en matière d'investissement. Notre équipe est également disponible pour répondre à toutes vos questions et vous guider tout au long du processus.
        </p>
      </div>
    </div>
  </div>

  );
};
