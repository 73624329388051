import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="row">
        <div className="col-xs-12 col-md-5">
          <img src="img/about.png" className="img-responsive" alt="About" />
        </div>
        <div className="col-xs-12 col-md-7">
          <div className="about-text">
            <h2>Une approche intelligente pour des investissements performants</h2>
            <p>{props.data ? props.data.paragraph : "loading..."}</p>
            <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
            <h2>Pourquoi choisir MGB ?</h2>
            <div className="list-style">
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <ul>
                  {props.data
                    ? props.data.Why.map((d, i) => <li key={`${d}-${i}`}>{d}</li>)
                    : "loading"}
                </ul>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <ul>
                  {props.data
                    ? props.data.Why2.map((d, i) => <li key={`${d}-${i}`}>{d}</li>)
                    : "loading"}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-text more">
        <h2>Rendements Recherchés</h2>
        <div className="flex-container">
          <div className="col-xs-12 col-md-9">
            <p style={{marginLeft:"-14px"}}>
              Nous recherchons un gain mensuel de 2,21%, ceci correspond à un gain annuel de 30%.
              Pour rappel, le retour moyen d'un placement sur le MSCI WORLD est de 8,6 %.
              Un rendement supérieur de 250%, impliquant donc des risques plus importants.
              La stratégie a été testée sur une période de 10 ans, couvrant 2012 à 2022.
            </p>
          </div>
          <div className="col-xs-12 col-md-3">
            <img src="img/perf.png" className="perf" alt="Performance" />
          </div>
        </div>
        <h2>Solution simple d'utilisation</h2>
        <p>
          Vous devrez simplement créer votre compte sur un broker, déposer vos fonds et
          nous transmettre les informations d’identifications du sous-compte (ces informations
          vous seront transmises par le Broker dans un mail au moment de la création du sous-compte).
          Attention à ne jamais transmettre vos identifiants et mots de passe de connexion au compte ICMarkets,
          ceux-ci vous sont propres et vous permettent de rester maître de vos fonds.
        </p>
      </div>
    </div>
  );
};
