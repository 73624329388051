import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="section-title">
        <h2>Notre équipe</h2>
        <div className="section-title-description text-center">
          <p>
            Notre équipe dévouée et compétente vous guide à chaque étape de
            votre parcours financier. Nous mettons constamment à jour nos
            connaissances pour vous offrir les meilleures solutions. Faites-nous
            confiance pour une expérience client exceptionnelle.
          </p>
        </div>
      </div>
      <div className="team-container">
        {props.data
          ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="team-member">
                {/* <div className="thumbnail">
                  <img src={d.img} alt={`${d.name}`} className="team-img" /> */}
                  <div className="caption">
                    <h4>{d.name}</h4>
                    <p>{d.job}</p>
                    <a href={d.ln_link}>
                      <img
                        src={"img/ln-long.png"}
                        style={{
                          marginTop: "15px",
                          marginBottom: "15px", // remove if picture is back
                          width: "145px",
                          height: "36.28px",
                        }}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              // </div>
            ))
          : "loading"}
      </div>
    </div>
  );
};
