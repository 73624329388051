import React from "react";
import Flyer from "../flyer.pdf";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-md-14 intro-text">
              <h1>
                Boostez vos trades,
                <br /> maximisez vos profits !
              </h1>
              <p>
                Optimisez votre portefeuille et exploitez les opportunités du
                marché grâce à notre expertise et à nos outils innovants de
                trading forex.
              </p>
            </div>
          </div>
          <div class="button-container">
            <a
              href={Flyer}
              download="Example-PDF-document"
              target="_blank"
              rel="noreferrer"
              className="btn btn-header btn-lg page-scroll"
            >
              Je me renseigne
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
