import React from "react";

export const Services = (props) => {
  return (
    <div id="services">
      <div className="section-title text-center">
        <h2>Nos Tarifs</h2>
      </div>
      <div className="section-title-description text-center">
        <p>
          {/* add section description here */}
        </p>
      </div>
      <div className="services-container">
        {props.data
          ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="service">
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <h4>{d.desc}</h4>
                  {d.text.map((textItem, index) => (
                    <p key={index}>{textItem}</p>
                  ))}
                </div>
                <a
                  href="#contact"
                  className="btn btn-tarifs btn-lg page-scroll"
                >
                  Nous contacter
                </a>
              </div>
            ))
          : "loading"}
      </div>
      <div className="services-conditions">
        <p>
          (1) En participant à notre programme de parrainage, vous bénéficiez d'une remise de 25% sur votre prochaine facture pour chaque nouveau client parrainé. Pour profiter de cette offre, votre filleul doit s'inscrire en utilisant votre code de parrainage unique et compléter sa première transaction. L'offre est valable une seule fois par parrainé et non cumulable avec d'autres promotions en cours. La remise sera appliquée à votre facture suivante après validation de l'inscription et de l'achat du filleul. Consultez nos conditions générales pour plus de détails ou contactez notre service client.
        </p>
      </div>
    </div>
  );
};
