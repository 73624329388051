import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { Footer } from "./components/footer";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MentionsLegales from "./components/MentionsLegales";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    setLandingPageData(JsonData);

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
      // if (window.scrollY > window.innerHeight) {
      //   setIsExpanded(true);
      // } else if (window.scrollY <= window.innerHeight) {
      //   setIsExpanded(false);
      // }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <div>
              <Navigation scrollPosition={scrollPosition} />
              <Header data={landingPageData.Header} />
              <About data={landingPageData.About} />
              <Testimonials data={landingPageData.Testimonials} />
              <Services data={landingPageData.Services} />
              <Team data={landingPageData.Team} />
              <Gallery data={landingPageData.Gallery} />
              <Contact data={landingPageData.Contact} />
              <Footer />
            </div>
          }
        />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
      </Routes>
    </Router>
  );
};

// <Features data={landingPageData.Features} />

export default App;
